import React from "react";
import ComingSoon from "./ComingSoon";
// Import the functions you need from the SDKs you need

function App() {
  return (
    <div className="App">
      <ComingSoon />
    </div>
  );
}

export default App;
